import React from "react"
import { useIntl } from "gatsby-plugin-intl"

const Footer = () => {
    const intl = useIntl()    
    
    return (
        <div className="grid grid-cols-9 gap-4 mx-auto max-w-screen-xl relative py-10">
             
             <div className="col-span-9 md:col-span-3">
                <div className="flex justify-center md:justify-start ">
                   
                    <div className="max-w-sm rounded-lg overflow-hidden shadow-lg mt-3">
                        <iframe 
                        title="HMD Facebook page"
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FHMDItaliaOfficial%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=719950322099554" 
                        width="340" 
                        height="130"
                        style={{border:'none',overflow:'hidden'}} 
                        scrolling="no"
                        frameBorder="0" 
                        // allowTransparency="true" 
                        // allow="encrypted-media"
                         />
                            
                    </div>
                </div>
            </div>

            <div className="col-span-9 md:col-span-3 text-white">
                <h2 className="text-2xl mb-3">{intl.formatMessage({ id: "followUsOn" })}</h2>
                <ul>
                    <li><b>Facebook:</b> <a className="underline" href="https://www.facebook.com/HMDItaliaOfficial/" target="_blank" rel="noreferrer">HMD Italia</a></li> 
                    <li><b>Youtube:</b> <a className="underline" href="https://www.youtube.com/c/HwalMooDoItalia" target="_blank" rel="noreferrer">Hwal Moo Do</a></li> 
                </ul>            
            </div>
            <div className="col-span-9 md:col-span-3 text-white">
                <h2 className="text-2xl mb-3">{intl.formatMessage({ id: "contacts" })}</h2>
                <ul>
                    <li><b>Email:</b> hwalmoodo.official@gmail.com</li>
                    <li><b>Tel:</b> +39 393 564 5180</li>
                </ul>

                
            </div>
           
        
        </div>
    )
}

export default Footer;